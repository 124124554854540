<template>
  <section>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="form-title">{{ trans[lang].title }}</div>

    <div class="form-container">
      <b-field
        :label="trans[lang].form.name"
        :type="form.name.status"
        :message="form.name.responce"
      >
        <b-input type="text" v-model="form.name.data" required></b-input>
      </b-field>

      <b-field
        :label="trans[lang].form.email"
        :type="form.email.status"
        :message="form.email.responce"
      >
        <b-input type="email" v-model="form.email.data" required></b-input>
      </b-field>

      <b-field
        :label="trans[lang].form.phone"
        :type="form.phone.status"
        :message="form.phone.responce"
      >
        <b-input type="text" v-model="form.phone.data"></b-input>
      </b-field>

      <b-field
        :label="trans[lang].form.comment"
        :type="form.comment.status"
        :message="form.comment.responce"
      >
        <b-input
          maxlength="200"
          type="textarea"
          v-model="form.comment.data"
        ></b-input>
      </b-field>

      <b-message v-if="form.result" :type="form.status">{{form.responce}}</b-message>

      <b-button v-if="form.visible" @click="sendMessage()" type="is-dark">{{ trans[lang].button }}</b-button>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
import formValidator from '@/mixins/formValidator';
export default {
  name: "ContactForm",
  data() {
    return {
      trans: {
        en: {
          title: "Request a quote",
          form: {
            name: "Name",
            email: "Email",
            phone: "Phone",
            comment: "Message",
          },
          button: "Send Message",
        },
        nl: {
          title: "Een offerte aanvragen",
          form: {
            name: "Naam",
            email: "Email",
            phone: "Telefoon",
            comment: "Bericht",
          },
          button: "Bericht versturen",
        },
      },
      isLoading: false,
      form: {
        name: {
          data: "",
          status: "",
          responce: "",
        },
        email: {
          data: "",
          status: "",
          responce: "",
        },
        phone: {
          data: "",
          status: "",
          responce: "",
        },
        comment: {
          data: "",
          status: "",
          responce: "",
        },
        human: {
          lable: "",
          summ: null,
          data: null,
          status: "",
          responce: "",
        },
        visible: true,
        responce: "",
        result: false,
        status: "",
      },
      token: null
    };
  },
  mixins: [formValidator],
  methods: {
    sendMessage() {
      if (this.emailCheck("email") & this.formCheck("phone") & this.formCheck("name") & this.formCheck("comment")) {
        //this.form.visible = false;
        this.isLoading = true;    
        //begin post --------------------------------------------
        this.axios
          .post("https://prysma-constructions.com/api/sendMessage.php", {
            action: "send",
            name: this.form.name.data,
            email: this.form.email.data,
            phone: this.form.phone.data,
            message: this.form.comment.data,
            token: this.$store.state.token
          })
          .then((response) => {
            this.isLoading = false;
            this.form.result = true;
            this.form.responce = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.form.visible = false;
            } else {
              this.form.status = "is-danger";
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.responce = "Ooops! Something went wrong. Please try again.";
      }
    },
  },
  computed: {
    ...mapState(["lang"]),
  },
};
</script>
<style scoped>
.form-title {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 2em;
  text-transform: uppercase;
  position: relative;
}
.form-container {
  background: #ffffff;
  margin-top: 50px;
  padding: 40px 40px 40px 40px;
  border-left: 2px #405365 solid;
}
</style>
