export default {
    methods: {
         emailCheck(e) {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
            if (this.form[e].data === "" || !re.test(this.form[e].data)) {
              this.form[e].status = "is-danger";
              this.form[e].responce = this.$locales[this.$store.state.lang].form.email;
              return false;
            } else {
              this.form[e].responce = null;
              this.form[e].status = "is-success";
              return true;
            }
          },
          passCheck(e) {
            let paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
            console.log(!this.form[e].data.match(paswd));
            if (this.form[e].data === "" || !this.form[e].data.match(paswd)) {
              this.form[e].responce =
                "Please enter a Password [7 to 15 characters which contain at least one numeric digit and a special character]";
              this.form[e].status = "is-danger";
              return false;
            } else {
              this.form[e].message = null;
              this.form[e].status = "is-success";
              return true;
            }
          },
          formCheck(e) {
            if (this.form[e].data === "") {
              this.form[e].status = "is-danger";
              this.form[e].responce = this.$locales[this.$store.state.lang].form.text;
              return false;
            } else {
              this.form[e].status = "is-success";
              return true;
            }
          },
          humanCheck(e) {
            if (this.form[e].data == 4) {
              this.form[e].status = "is-success";
              this.form[e].responce = "Human, eh?";
              return true;
            } else {
              this.form[e].status = "is-danger";
              this.form[e].responce = this.$locales[this.$store.state.lang].form.human;
              return false;
            }
        },    
    }
};