<template>
  <div class="chat-now-button"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="{ active: hover }"
    @click = "openUrl()"
  >
    <div class="bt-title"><b-icon pack="fab" icon="whatsapp"></b-icon>{{indata.attributes.Title}}</div>
  </div>
</template>

<script>
export default {
  name: "ChatNow",
  props: {
    indata: Object
  },
  data() {
    return {
      hover: false  
    };
  },

  methods:{
    openUrl(){
      window.open(this.indata.attributes.Description, '_blank');
    }
  }
};
</script>

<style scoped>
  .chat-now-button{
    border-radius: 10px;
    position: fixed;
    cursor: pointer;
    bottom: 40px;
    right: 40px;
    z-index: 1000;
    background: #000000;
      /* border-left: solid #000000 1px;
    border-bottom: solid #000000 1px;
    padding-left:10px;
    padding-bottom:10px; */

  }

  .active{
    background: #00b40f;
  }
  .bt-title{
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 10px 20px 10px 10px;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    text-align: center;
    /* border-bottom: solid #3a3a3a 1px; */
    /* margin-left: -100px; */
  }

  @media only screen and (max-width: 600px) {
  
  }

</style>