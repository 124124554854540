<template>
<section class="section-body">
  <!-- <div id="roller"></div> -->
  <div class="ruler">
  <div class="home" id="home">
    <div></div>
    <div class="welcome">
      <div>      
      <!-- <img src="/img/logo_lg_white.svg" width="400px" alt=""> -->
      <svg class="home-logo" enable-background="new 0 0 400 300" version="1.1" viewBox="0 0 400 300" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
        <!-- <path class="logo-title lt1" d="m91 43.8v10.3h-2.3v-29.8h13.1c12.9 0 12.9 19.5 0 19.5h-10.8zm0-17.3v15.3h10.8c9.9 0 9.9-15.3 0-15.3h-10.8z"/>
        <path class="logo-title lt1" d="m140.3 54.1h-3l-10.3-11.3h-8.1v11.3h-2.3v-29.8h13.1c6.5 0 9.7 4.6 9.8 9.3 0 4.9-3.1 9.1-9.7 9.1l10.5 11.4zm-21.4-13.4h10.6c5.2 0 7.5-2.9 7.5-7.1 0-3.5-2.3-7.1-7.3-7.1h-10.8v14.2z"/>
        <path class="logo-title lt1" d="m153.2 42.2-11.6-17.8h2.7l10 15.6 10.1-15.7h2.7l-11.7 17.8v11.9h-2.3v-11.8h0.1z"/>
        <path class="logo-title lt1" d="m190.6 30.1c-2.2-3.5-5.7-4-9-4-3.5 0-9 1.3-9 6 0 3.8 4.1 4.7 9.1 5.6 5.7 1.1 11.7 2.1 11.7 8.6 0 6.7-6.8 8.3-11.8 8.3-4.6 0-9.9-1.9-12.2-6.5l2-1c1.8 3.6 6.5 5.4 10.2 5.4s9.5-1 9.5-6.3c0-4.5-4.9-5.4-9.8-6.4-5.4-1.1-11-2.2-11-7.7-0.1-6.3 6.4-8.3 11.3-8.3 4.3 0 8 0.7 10.9 5.1l-1.9 1.2z"/>
        <path class="logo-title lt1" d="m224.2 27.7-11.4 17.4h-0.7l-11.5-17.4v26.5h-2.3v-29.9h2.7l11.4 17.4 11.3-17.4h2.8v29.8h-2.3v-26.4z"/>
        <path class="logo-title lt1" d="m256.8 54.1-3.3-7.3h-17.8l-3.2 7.3h-2.5l13.4-29.8h2.5l13.4 29.8h-2.5zm-4.2-9.4-8-18.1-8 18.1h16z"/>
        <path class="logo-subtitle lst1" d="m96.9 66.6c-0.9 0.9-2.2 1.4-3.4 1.4-3.2 0-4.8-2.2-4.8-4.7s1.6-4.8 4.8-4.8c1.2 0 2.5 0.5 3.4 1.4l-0.5 0.4c-0.8-0.8-1.9-1.2-2.9-1.2-2.7 0-4.1 1.9-4.1 4.2 0 2.2 1.4 4.1 4.1 4.1 1.1 0 2.1-0.4 2.9-1.2l0.5 0.4z"/>
        <path class="logo-subtitle lst1" d="m112.5 63.2c0 2.5-1.6 4.8-4.7 4.8s-4.7-2.4-4.7-4.9 1.6-4.8 4.7-4.8c3.1 0.1 4.7 2.4 4.7 4.9zm-8.8 0c0 2.1 1.4 4.2 4 4.2 2.7 0 4-2.1 4-4.2 0-2.2-1.3-4.2-4-4.2s-4 2-4 4.2z"/>
        <path class="logo-subtitle lst1" d="m119.3 58.5 6.3 7.7v-7.7h0.7v9.4h-0.2l-6.3-7.7v7.7h-0.7v-9.4h0.2z"/>
        <path class="logo-subtitle lst1" d="m139.5 60.3c-0.7-1.1-1.8-1.3-2.8-1.3-1.1 0-2.8 0.4-2.8 1.9 0 1.2 1.3 1.5 2.9 1.8 1.8 0.3 3.7 0.7 3.7 2.7 0 2.1-2.1 2.6-3.7 2.6-1.5 0-3.1-0.6-3.8-2l0.6-0.3c0.6 1.1 2 1.7 3.2 1.7s3-0.3 3-2c0-1.4-1.5-1.7-3.1-2-1.7-0.3-3.5-0.7-3.5-2.4 0-2 2-2.6 3.6-2.6 1.3 0 2.5 0.2 3.4 1.6l-0.7 0.3z"/>
        <path class="logo-subtitle lst1" d="m149.3 59.2h-3.4v-0.6h7.5v0.6h-3.3v8.7h-0.7v-8.7h-0.1z"/>
        <path class="logo-subtitle lst1" d="m167.1 67.9h-0.9l-3.2-3.5h-2.6v3.5h-0.7v-9.4h4.1c2 0 3.1 1.5 3.1 2.9 0 1.5-1 2.9-3 2.9l3.2 3.6zm-6.7-4.2h3.3c1.6 0 2.4-0.9 2.4-2.2 0-1.1-0.7-2.2-2.3-2.2h-3.4v4.4z"/>
        <path class="logo-subtitle lst1" d="m180.6 58.5v5.8c0 4.9-7.2 4.9-7.2 0v-5.8h0.7v5.8c0 4 5.8 4 5.8 0v-5.8h0.7z"/>
        <path class="logo-subtitle lst1" d="m195.1 66.6c-0.9 0.9-2.2 1.4-3.4 1.4-3.2 0-4.8-2.2-4.8-4.7s1.6-4.8 4.8-4.8c1.2 0 2.5 0.5 3.4 1.4l-0.5 0.4c-0.8-0.8-1.9-1.2-2.9-1.2-2.7 0-4.1 1.9-4.1 4.2 0 2.2 1.4 4.1 4.1 4.1 1.1 0 2.1-0.4 2.9-1.2l0.5 0.4z"/>
        <path class="logo-subtitle lst1" d="m204.1 59.2h-3.4v-0.6h7.5v0.6h-3.4v8.7h-0.7v-8.7z"/>
        <path class="logo-subtitle lst1" d="m214.4 67.9v-9.4h0.7v9.4h-0.7z"/>
        <path class="logo-subtitle lst1" d="m231.4 63.2c0 2.5-1.6 4.8-4.7 4.8s-4.7-2.4-4.7-4.9 1.6-4.8 4.7-4.8c3.1 0.1 4.7 2.4 4.7 4.9zm-8.8 0c0 2.1 1.4 4.2 4 4.2 2.7 0 4-2.1 4-4.2 0-2.2-1.3-4.2-4-4.2-2.7 0.1-4 2-4 4.2z"/>
        <path class="logo-subtitle lst1" d="m238.2 58.5 6.3 7.7v-7.7h0.7v9.4h-0.3l-6.3-7.7v7.7h-0.7v-9.4h0.3z"/>
        <path class="logo-subtitle lst1" d="m258.4 60.3c-0.7-1.1-1.8-1.3-2.8-1.3-1.1 0-2.8 0.4-2.8 1.9 0 1.2 1.3 1.5 2.9 1.8 1.8 0.3 3.7 0.7 3.7 2.7 0 2.1-2.1 2.6-3.7 2.6-1.5 0-3.1-0.6-3.8-2l0.6-0.3c0.6 1.1 2 1.7 3.2 1.7s3-0.3 3-2c0-1.4-1.5-1.7-3.1-2-1.7-0.3-3.5-0.7-3.5-2.4 0-2 2-2.6 3.6-2.6 1.3 0 2.5 0.2 3.4 1.6l-0.7 0.3z"/>
        <polygon class="logo-el lel1"  points="36.1 37 65.3 37 50.7 62.3"/>
        <circle class="logo-el lel2"  cx="50.7" cy="45.4" r="27.2"/>
        <path class="logo-el lel3"  d="m53.1 12.5 25 14.4c1.5 0.9 2.4 2.4 2.4 4.1v28.8c0 1.7-0.9 3.3-2.4 4.1l-25 14.5c-1.5 0.9-3.3 0.9-4.8 0l-24.9-14.4c-1.5-0.9-2.4-2.4-2.4-4.1v-28.9c0-1.7 0.9-3.3 2.4-4.1l25-14.4c1.4-0.9 3.2-0.9 4.7 0z"/>
        <path class="logo-el lel4"  d="m78.9 62.5h-56.4c-0.6 0-1-0.7-0.7-1.2l28.2-48.7c0.3-0.5 1.1-0.5 1.4 0l28.2 48.7c0.3 0.6-0.1 1.2-0.7 1.2z"/>
        <path class="logo-el lel5"  d="M23.2,28.2h55c1.1,0,1.8,1.2,1.2,2.1L52,77.8c-0.5,1-1.9,1-2.4,0L22,30.3C21.5,29.4,22.1,28.2,23.2,28.2z"/>
        <line class="logo-el lel6"  x1="50.7" x2="50.7" y1="62.3" y2="79"/>
        <line class="logo-el lel7"  x1="65.3" x2="80" y1="37" y2="28.5"/>
        <line class="logo-el lel8"  x1="36.1" x2="21.4" y1="37" y2="28.6"/> -->

<path class="logo-title" d="M62.4,200.9c-3.3-2.8-7.9-4.2-13.7-4.2H31.3v44.8h6.4V228h11.1c5.8,0,10.4-1.4,13.7-4.2c3.3-2.8,5-6.6,5-11.5
	C67.4,207.5,65.8,203.7,62.4,200.9 M57.8,219.8c-2.1,1.7-5.2,2.6-9.3,2.6H37.7v-20.1h10.9c4.1,0,7.1,0.9,9.3,2.6
	c2.1,1.7,3.2,4.2,3.2,7.5C61,215.6,60,218.1,57.8,219.8"/>
<path class="logo-title" d="M117.2,226.5c3.2-1.1,5.6-2.9,7.3-5.3c1.7-2.4,2.6-5.4,2.6-8.8c0-4.9-1.7-8.7-5-11.5c-3.3-2.8-7.9-4.2-13.7-4.2
	H90.9v5.6h17.3c4.1,0,7.1,0.9,9.3,2.6c2.1,1.7,3.2,4.2,3.2,7.5c0,3.2-1.1,5.7-3.2,7.5c-2.1,1.8-5.2,2.6-9.3,2.6H90.9v19.1h6.4v-13.6
	h11.1c0.7,0,1.6,0,2.8-0.1l9.7,13.8h7L117.2,226.5z"/>
<polygon class="logo-title" points="179.5,196.8 165.1,220.4 150.8,196.8 143.9,196.8 161.7,225.9 161.7,241.5 168,241.5 168,226.1 
	185.8,196.8 "/>
<path class="logo-title" d="M233.6,222.6c-1.5-1.7-3.2-2.9-5.3-3.7c-2-0.8-4.8-1.6-8.1-2.4c-2.6-0.6-4.7-1.2-6.2-1.7
	c-1.5-0.5-2.8-1.3-3.8-2.2c-1-1-1.5-2.2-1.5-3.7c0-2.1,0.9-3.8,2.7-5.1c1.8-1.3,4.5-2,8.2-2c2,0,4.1,0.3,6.3,0.9
	c2.1,0.6,4.1,1.5,6,2.7l2.1-5.1c-1.8-1.3-4-2.3-6.6-2.9c-2.6-0.7-5.2-1-7.8-1c-3.8,0-7,0.6-9.5,1.7c-2.6,1.1-4.5,2.7-5.7,4.6
	c-1.3,1.9-1.9,4.1-1.9,6.5c0,2.9,0.7,5.1,2.2,6.8c1.5,1.7,3.2,2.9,5.3,3.7c2.1,0.8,4.8,1.6,8.2,2.4c2.7,0.6,4.8,1.2,6.2,1.7
	c1.5,0.5,2.7,1.2,3.7,2.2c1,1,1.5,2.2,1.5,3.7c0,2.1-0.9,3.8-2.7,5c-1.8,1.3-4.6,1.9-8.3,1.9c-2.8,0-5.6-0.5-8.2-1.4
	c-2.7-1-4.9-2.2-6.6-3.7l-2.4,5c1.8,1.7,4.3,3,7.4,4.1c3.1,1,6.4,1.6,9.8,1.6c3.8,0,7-0.6,9.6-1.7c2.6-1.1,4.5-2.7,5.8-4.6
	c1.3-1.9,1.9-4.1,1.9-6.4C235.8,226.5,235.1,224.3,233.6,222.6"/>
<polygon class="logo-title" points="306.8,196.8 301.6,196.8 283.2,228.2 264.5,196.8 259.2,196.8 259.2,241.5 265.4,241.5 265.4,209.1 
	281.6,236.1 284.5,236.1 300.7,208.9 300.8,241.5 306.9,241.5 "/>
<polygon class="logo-title" points="354.3,196.8 347.9,196.8 327.6,241.5 334.2,241.5 351.1,203.3 367.9,241.5 374.6,241.5 "/>
<path class="logo-subtitle" d="M34.5,272.2c-1-0.5-1.8-1.3-2.3-2.3c-0.6-1-0.8-2-0.8-3.2c0-1.2,0.3-2.3,0.8-3.2c0.6-1,1.3-1.7,2.3-2.3
	c1-0.5,2.1-0.8,3.4-0.8c1,0,1.8,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2,1.4l-1.1,1.1c-0.9-1-2.1-1.5-3.5-1.5c-0.9,0-1.7,0.2-2.5,0.6
	c-0.7,0.4-1.3,1-1.7,1.7c-0.4,0.7-0.6,1.5-0.6,2.4c0,0.9,0.2,1.7,0.6,2.4c0.4,0.7,1,1.3,1.7,1.7c0.7,0.4,1.6,0.6,2.5,0.6
	c1.4,0,2.5-0.5,3.5-1.5l1.1,1.1c-0.6,0.6-1.3,1.1-2.1,1.4c-0.8,0.3-1.7,0.5-2.7,0.5C36.6,273,35.5,272.7,34.5,272.2"/>
<path class="logo-subtitle" d="M62.6,272.2c-1-0.5-1.8-1.3-2.4-2.3c-0.6-1-0.9-2-0.9-3.2c0-1.2,0.3-2.3,0.9-3.2c0.6-1,1.4-1.7,2.4-2.3
	c1-0.5,2.1-0.8,3.4-0.8c1.2,0,2.4,0.3,3.4,0.8c1,0.5,1.8,1.3,2.3,2.3c0.6,1,0.8,2,0.8,3.2s-0.3,2.3-0.8,3.2c-0.6,1-1.3,1.7-2.3,2.3
	c-1,0.5-2.1,0.8-3.4,0.8C64.7,273,63.6,272.7,62.6,272.2 M68.4,270.8c0.7-0.4,1.3-1,1.7-1.7c0.4-0.7,0.6-1.5,0.6-2.4
	c0-0.9-0.2-1.7-0.6-2.4c-0.4-0.7-1-1.3-1.7-1.7c-0.7-0.4-1.5-0.6-2.4-0.6c-0.9,0-1.7,0.2-2.5,0.6c-0.7,0.4-1.3,1-1.7,1.7
	c-0.4,0.7-0.6,1.5-0.6,2.4c0,0.9,0.2,1.7,0.6,2.4c0.4,0.7,1,1.3,1.7,1.7c0.7,0.4,1.6,0.6,2.5,0.6C66.8,271.4,67.6,271.2,68.4,270.8"
	/>
<polygon class="logo-subtitle" points="101.4,260.5 101.4,272.9 99.9,272.9 92.5,263.6 92.5,272.9 90.7,272.9 90.7,260.5 92.2,260.5 
	99.6,269.7 99.6,260.5 "/>
<path class="logo-subtitle" d="M121.5,272.6c-0.9-0.3-1.5-0.7-2-1.1l0.7-1.4c0.5,0.4,1.1,0.8,1.8,1c0.7,0.3,1.5,0.4,2.3,0.4
	c1,0,1.8-0.2,2.3-0.5c0.5-0.3,0.8-0.8,0.8-1.4c0-0.4-0.1-0.8-0.4-1c-0.3-0.3-0.6-0.5-1-0.6c-0.4-0.1-1-0.3-1.7-0.5
	c-0.9-0.2-1.7-0.4-2.3-0.7c-0.6-0.2-1.1-0.6-1.5-1c-0.4-0.5-0.6-1.1-0.6-1.9c0-0.7,0.2-1.3,0.5-1.8c0.3-0.5,0.9-1,1.6-1.3
	c0.7-0.3,1.6-0.5,2.6-0.5c0.7,0,1.4,0.1,2.2,0.3c0.7,0.2,1.3,0.5,1.8,0.8l-0.6,1.4c-0.5-0.3-1.1-0.6-1.7-0.8
	c-0.6-0.2-1.2-0.3-1.7-0.3c-1,0-1.8,0.2-2.3,0.5c-0.5,0.4-0.8,0.8-0.8,1.4c0,0.4,0.1,0.8,0.4,1c0.3,0.3,0.6,0.5,1.1,0.6
	c0.4,0.1,1,0.3,1.7,0.5c0.9,0.2,1.7,0.4,2.2,0.7c0.6,0.2,1.1,0.6,1.5,1c0.4,0.5,0.6,1.1,0.6,1.9c0,0.6-0.2,1.2-0.5,1.8
	c-0.4,0.5-0.9,1-1.6,1.3c-0.7,0.3-1.6,0.5-2.7,0.5C123.3,273,122.4,272.9,121.5,272.6"/>
<polygon class="logo-subtitle" points="149.4,262 145.2,262 145.2,260.5 155.4,260.5 155.4,262 151.2,262 151.2,272.9 149.4,272.9 "/>
<path class="logo-subtitle" d="M181.2,272.9l-2.7-3.8c-0.3,0-0.6,0-0.8,0h-3.1v3.8h-1.8v-12.4h4.8c1.6,0,2.9,0.4,3.8,1.1
	c0.9,0.8,1.4,1.8,1.4,3.2c0,1-0.2,1.8-0.7,2.4c-0.5,0.7-1.1,1.2-2,1.5l2.9,4.2H181.2z M180.2,266.9c0.6-0.5,0.9-1.2,0.9-2.1
	c0-0.9-0.3-1.6-0.9-2.1c-0.6-0.5-1.4-0.7-2.6-0.7h-3v5.6h3C178.8,267.6,179.6,267.4,180.2,266.9"/>
<path class="logo-subtitle" d="M202.6,271.6c-0.9-0.9-1.4-2.3-1.4-4.1v-7h1.8v7c0,2.7,1.2,4,3.5,4c1.1,0,2-0.3,2.6-1c0.6-0.7,0.9-1.7,0.9-3v-7
	h1.7v7c0,1.8-0.5,3.1-1.4,4.1c-0.9,0.9-2.2,1.4-3.9,1.4C204.8,273,203.5,272.5,202.6,271.6"/>
<path class="logo-subtitle" d="M233,272.2c-1-0.5-1.8-1.3-2.3-2.3c-0.6-1-0.8-2-0.8-3.2c0-1.2,0.3-2.3,0.8-3.2c0.6-1,1.3-1.7,2.3-2.3
	c1-0.5,2.1-0.8,3.4-0.8c1,0,1.8,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2,1.4l-1.1,1.1c-0.9-1-2.1-1.5-3.5-1.5c-0.9,0-1.7,0.2-2.5,0.6
	c-0.7,0.4-1.3,1-1.7,1.7c-0.4,0.7-0.6,1.5-0.6,2.4c0,0.9,0.2,1.7,0.6,2.4c0.4,0.7,1,1.3,1.7,1.7c0.7,0.4,1.6,0.6,2.5,0.6
	c1.4,0,2.5-0.5,3.5-1.5l1.1,1.1c-0.6,0.6-1.3,1.1-2.1,1.4c-0.8,0.3-1.7,0.5-2.7,0.5C235.1,273,234,272.7,233,272.2"/>
<polygon class="logo-subtitle" points="261.4,262 257.2,262 257.2,260.5 267.4,260.5 267.4,262 263.2,262 263.2,272.9 261.4,272.9 "/>
<rect x="284.9" y="260.5" class="logo-subtitle" width="1.8" height="12.4"/>
<path class="logo-subtitle" d="M308.1,272.2c-1-0.5-1.8-1.3-2.4-2.3c-0.6-1-0.9-2-0.9-3.2c0-1.2,0.3-2.3,0.9-3.2c0.6-1,1.4-1.7,2.4-2.3
	c1-0.5,2.1-0.8,3.4-0.8c1.2,0,2.4,0.3,3.4,0.8c1,0.5,1.8,1.3,2.3,2.3c0.6,1,0.8,2,0.8,3.2s-0.3,2.3-0.8,3.2c-0.6,1-1.3,1.7-2.3,2.3
	c-1,0.5-2.1,0.8-3.4,0.8C310.3,273,309.1,272.7,308.1,272.2 M314,270.8c0.7-0.4,1.3-1,1.7-1.7c0.4-0.7,0.6-1.5,0.6-2.4
	c0-0.9-0.2-1.7-0.6-2.4c-0.4-0.7-1-1.3-1.7-1.7c-0.7-0.4-1.5-0.6-2.4-0.6c-0.9,0-1.7,0.2-2.5,0.6c-0.7,0.4-1.3,1-1.7,1.7
	c-0.4,0.7-0.6,1.5-0.6,2.4c0,0.9,0.2,1.7,0.6,2.4c0.4,0.7,1,1.3,1.7,1.7c0.7,0.4,1.6,0.6,2.5,0.6C312.4,271.4,313.2,271.2,314,270.8
	"/>
<polygon class="logo-subtitle" points="347,260.5 347,272.9 345.5,272.9 338.1,263.6 338.1,272.9 336.3,272.9 336.3,260.5 337.8,260.5 
	345.2,269.7 345.2,260.5 "/>
<path class="logo-subtitle" d="M367.1,272.6c-0.9-0.3-1.5-0.7-2-1.1l0.7-1.4c0.5,0.4,1.1,0.8,1.8,1c0.7,0.3,1.5,0.4,2.3,0.4
	c1,0,1.8-0.2,2.3-0.5c0.5-0.3,0.8-0.8,0.8-1.4c0-0.4-0.1-0.8-0.4-1c-0.3-0.3-0.6-0.5-1-0.6c-0.4-0.1-1-0.3-1.7-0.5
	c-0.9-0.2-1.7-0.4-2.3-0.7c-0.6-0.2-1.1-0.6-1.5-1c-0.4-0.5-0.6-1.1-0.6-1.9c0-0.7,0.2-1.3,0.5-1.8c0.3-0.5,0.9-1,1.6-1.3
	c0.7-0.3,1.6-0.5,2.6-0.5c0.7,0,1.4,0.1,2.2,0.3c0.7,0.2,1.3,0.5,1.8,0.8l-0.6,1.4c-0.5-0.3-1.1-0.6-1.7-0.8
	c-0.6-0.2-1.2-0.3-1.7-0.3c-1,0-1.8,0.2-2.3,0.5c-0.5,0.4-0.8,0.8-0.8,1.4c0,0.4,0.1,0.8,0.4,1c0.3,0.3,0.6,0.5,1.1,0.6
	c0.4,0.1,1,0.3,1.7,0.5c0.9,0.2,1.7,0.4,2.2,0.7c0.6,0.2,1.1,0.6,1.5,1c0.4,0.5,0.6,1.1,0.6,1.9c0,0.6-0.2,1.2-0.5,1.8
	c-0.4,0.5-0.9,1-1.6,1.3c-0.7,0.3-1.6,0.5-2.7,0.5C368.9,273,368,272.9,367.1,272.6"/>
<!-- <polygon class="logo-el lel1" points="204.7,174.5 100.4,26.4 305.5,26.4 273.6,73.4 273.4,73.5 272.4,75.1 268.1,81.3 171.6,81.3 
	166.8,74.3 264.4,74.3 292.5,33.3 113.7,33.3 120.6,43.4 208.9,168.5 "/> -->
<!-- <polygon class="logo-el" points="204.7,174.5 100.4,26.4 305.5,26.4 268.1,81.3 171.6,81.3 166.8,74.3 264.4,74.3 292.5,33.3 
	113.7,33.3 120.6,43.4 208.9,168.5 "/>-->
<polygon class="logo-el lelm4" points="268.1,81.3 171.6,81.3 166.8,74.3 264.4,74.3 "/>
<polygon class="logo-el lelm3" points="305.5,26.4 268.1,81.3 264.4,74.3 292.5,33.3 "/>
<polygon class="logo-el lelm2" points="100.4,26.4 305.5,26.4 292.5,33.3 113.7,33.3 "/>
<polygon class="logo-el lelm1" points="204.7,174.5 100.4,26.4 113.7,33.3 208.9,168.5 "/>
<path class="logo-el lel1" d="M181.1,95.6l4.6,6.8l29.7,44l4.2,6.3l4.2-6.2l30-44.1l4.6-6.8H181.1z M219.6,140.2l-25.5-37.9h51.2L219.6,140.2
	z"/> 
      </svg>
      </div>
    </div>
  <!-- <div class="social"><img src="/img/facebook_icon.svg" width="20px" alt=""> <img src="/img/instagram_icon.svg" width="20px" alt=""></div> -->
 <div class="social" v-scroll-to="{ el: '.services', duration: 500, offset: -60 }"> <div class="downArrow bounce">
  <img width="40" height="40" alt="" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMzIgMzIiIGhlaWdodD0iMzJweCIgaWQ9InN5bnRoby5uZXQiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDMyIDMyIiB3aWR0aD0iMzJweCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PHBhdGggZD0iTTI0LjI4NSwxMS4yODRMMTYsMTkuNTcxbC04LjI4NS04LjI4OGMtMC4zOTUtMC4zOTUtMS4wMzQtMC4zOTUtMS40MjksMCAgYy0wLjM5NCwwLjM5NS0wLjM5NCwxLjAzNSwwLDEuNDNsOC45OTksOS4wMDJsMCwwbDAsMGMwLjM5NCwwLjM5NSwxLjAzNCwwLjM5NSwxLjQyOCwwbDguOTk5LTkuMDAyICBjMC4zOTQtMC4zOTUsMC4zOTQtMS4wMzYsMC0xLjQzMUMyNS4zMTksMTAuODg5LDI0LjY3OSwxMC44ODksMjQuMjg1LDExLjI4NHoiIGZpbGw9IiNmZmZmZmYiPjwvcGF0aD48L3N2Zz4=" />
</div></div>
  </div>
  <div class="pages">
  <!-- <div class="about section p-t-xl" id="about">
    <div class="container" v-if="servicesLoaded">
      <div class="content"><div class="title-container"><h1 class="section-title">{{$locales[lang].aboutus}}</h1></div></div>
      <div class="m-t-xl">
        about us text
      </div>
    </div>
  </div> -->
  <div class="services section p-t-xl" id="services">
    <div class="container" v-if="servicesLoaded">
      <div class="content"><div class="title-container"><h1 class="section-title">{{$locales[lang].services}}</h1></div></div>
      <div class="columns m-t-xl">
        <div class="column">
          <InfoItem :indata="listServices[0]"/>
        </div>
        <div class="column">
          <InfoItem :indata="listServices[1]"/>
        </div>
        <div class="column">
          <InfoItem :indata="listServices[2]"/>
        </div>
      </div>
    </div>
  </div>
  <div ref="section" class="why section p-t-xl" id="why">
    <div class="container" v-if="aboutLoaded">
      <div class="content"><div class="title-container"><h1 class="section-title">{{$locales[lang].why}}</h1></div></div>
      <div class="columns m-t-xl">
        <div class="column">
          <InfoItem :indata="listAbout[0]"/>
        </div>
        <div class="column">
          <InfoItem :indata="listAbout[1]"/>
        </div>
        <div class="column">
          <InfoItem :indata="listAbout[2]"/>
        </div>
      </div>
      <div v-if="listAbout[3]"  v-html="reMarked(listAbout[3].attributes.Description)"></div>
    </div>
  </div>
  <div class="portfolio section p-t-xl" id="portfolio">
    <div class="container" v-if="portfolioLoaded">
      <div class="content"><div class="title-container"><h1 class="section-title">{{$locales[lang].portfolio}}</h1></div></div>
      <div class="portfolio-grid m-t-xl">
          <template v-for="(item, index) in listPortfolio">
            <PortfolioItem :indata="item" v-bind:key="'p'+index" />
          </template>          
      </div>
    </div>
  </div>
    <!--to-activate-quote: <div class="quote section p-t-xl" id="quote">
    <div class="container">
      <div class="content"><div class="title-container"><h1 class="section-title">{{$locales[lang].quote}}</h1></div></div>
      <Quote />
    </div>
  </div> -->
    <div class="contact section p-t-xl" id="contact">
    <div class="container">
      <div class="content"><div class="title-container"><h1 class="section-title">{{$locales[lang].cont}}</h1></div></div>
      <div class="columns m-t-xl">
        <div class="column">
          <CallbackForm/>          
        </div>
        <div class="column">
          <ContactForm/>
        </div>
      </div>

      <div v-if="listAbout[4]" class="content m-t-lg"> 
        <div class="aftercontact-title is-5">{{listAbout[4].attributes.Title}}</div>
        <!-- <div v-html="marked(listAbout[3].description)"></div> -->
        <div v-html="reMarked(listAbout[4].attributes.Description)"></div>
      </div>

    </div>
  </div>




  </div>
  </div>
  <ChatNow class="chat-button" :indata="listAbout[5]" />
</section>
</template>

<script>
// @ is an alias to /src
import { marked } from "marked";
import { mapState } from "vuex";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import InfoItem from '@/components/InfoItem.vue'
import PortfolioItem from '@/components/PortfolioItem.vue'
//to-activate-quote: import Quote from '@/components/Quote.vue'
import ContactForm from '@/components/ContactForm.vue'
import CallbackForm from '@/components/CallbackForm.vue'
import ChatNow from '@/components/ChatNow.vue'

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause"
});


export default {
  name: 'HomeView',
  components: {
    InfoItem,
    PortfolioItem,
    //to-activate-quote: Quote,
    ContactForm,
    CallbackForm,
    ChatNow
  },
  data() {
    return {
      listAbout: {},
      aboutLoaded: false,
      listServices: {},
      servicesLoaded: false,
      listPortfolio: {},
      portfolioLoaded: false,
      menuHooks:["home","services","why","portfolio","contact"], //to-activate-quote: ["home","services","about","portfolio","quote","contact"],
    };
  },
  methods:{
    reMarked(text){
      return marked.parse(text); //=> <p>I am using <strong>markdown</strong>.</p>
    },
    getToken() {
      this.axios
        .post("https://prysma-constructions.com/api/gentocken.php",{
            action: "gen",
        })
        .then(response => {
          if (response.data.success) {
            this.$store.commit("SET_TOKEN", response.data.jwt);
            console.log("Token Generated:" + this.token);
          } else {
            console.log("Error generating token");
          }
        })
        .catch(error => {

          console.log(error);
        });
      //.finally(() => (this.loading = false));
    },
    loadAbout() {
     // console.log("loading about");
      //console.log(this.lang);
      // if()
      let getFrom = "https://newadmin.prysma-constructions.com/api/infos?populate[Image][fields][0]=url&locale="+this.lang;

      //console.log("getFrom:", getFrom);
      this.axios
        .get(getFrom)
        .then(response => {
          console.log(response);
          if (response.data.data.length > 0) {
            this.listAbout = response.data.data;
            console.log(this.listAbout[0]);
            this.aboutLoaded = true;
          } else {
            console.log("Error getting data");
          }
        })
        .catch(error => {
          this.hasNews = false;
          console.log(error);
        });
      //.finally(() => (this.loading = false));
    },
    loadServices() {
      // if()
      let getFrom = "https://newadmin.prysma-constructions.com/api/services?populate[Image][fields][0]=url&locale="+this.lang;
      this.axios
        .get(getFrom)
        .then(response => {
          if (response.data.data.length > 0) {
            this.listServices = response.data.data;
            //console.log(this.listServices);
            this.servicesLoaded = true;
          } else {
            console.log("Error getting data");
          }
        })
        .catch(error => {
          this.hasNews = false;
          console.log(error);
        });
      //.finally(() => (this.loading = false));
    },
    loadPortfolio() {
      // if()
      console.log("loading loadPortfolio");
      let getFrom = "https://newadmin.prysma-constructions.com/api/works?populate[Thumbnail][fields][0]=url&populate[Gallery][fields]=url&locale="+this.lang;
      this.axios
        .get(getFrom)
        .then(response => {
          if (response.data.data.length > 0) {
            this.listPortfolio = response.data.data;
            console.log(this.listPortfolio);
            this.portfolioLoaded = true;
          } else {
            console.log("Error getting data");
          }
        })
        .catch(error => {
          this.hasNews = false;
          console.log(error);
        });
      //.finally(() => (this.loading = false));
    },
    handleScroll: function() {
      // let sample = document.getElementById('home');
      // let sampleHeight = sample.getBoundingClientRect().height;
      // let sampleTopDist = sample.getBoundingClientRect().top;
      // let sampleBottomDist = sample.getBoundingClientRect().bottom;
      // console.log('sampleHeight: '+sampleHeight);
      // console.log('sampleTopDist: '+sampleTopDist);
      // console.log('sampleBottomDist: '+sampleBottomDist);

      // var roller = document.getElementById("roller");
      // var y = window.scrollY;
      // roller.style.top = y*1.15 +'px';

      this.menuHooks.find(index => {
          let el = document.getElementById(index);
          //let footer = document.getElementById("footer");
          let elHeight = el.getBoundingClientRect().height;
        // let topDist = el.getBoundingClientRect().top;
          let bottomDist = el.getBoundingClientRect().bottom;
          //if ((topDist <= 50) & (bottomDist + 70 >= elHeight)) {
          if ( bottomDist < elHeight+200) {
            let active = this.menuHooks.indexOf(index);
            if(active !== this.activeScroll){
              this.$store.commit("SET_ACTIVE", active);
              // roller.innerHTML = index;
            }
          }
      });
    },
    // isEven(val){
    //   console.log(val);
    //   if(val % 2 == 0){
    //     return {"span-col2":true};
    //   }else{
    //     return "";
    //   }
    // }
  },
  computed: {
    ...mapState(["lang","activeScroll","token"]),
  },
  watch:{
    lang(val){
      console.log(val);
      this.loadAbout();
      this.loadServices();
      this.loadPortfolio();
    }
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {

    //dataloaders
    this.loadAbout();
    this.loadServices();
    this.loadPortfolio();



    if(this.token==null){
      this.getToken();
    }


    document.addEventListener("scroll", this.handleScroll);
    
    //Logo Animation
    gsap.from(".logo-title", {
    duration:1.5, x:"3", transformOrigin: "50% 50%", opacity:0,
      scrollTrigger: {
        trigger:".logo-title",
        //markers:true,
        start:"top 75%", //when top of herman passes 75% viewport height
        end:"bottom 5%", //when bottom of herman passes 25% viewport height
        
            //events: onEnter onLeave onEnterBack onLeaveBack
        //toggleActions:"restart reset play"
            //options: play, pause, resume, reset, restart, complete, reverse,none
      }
    }).delay(0.2);

    gsap.from(".logo-subtitle", {
    duration:1.5, x:"3", transformOrigin: "50% 50%", opacity:0,
      scrollTrigger: {
        trigger:".logo-subtitle",
        start:"top 75%",
        end:"bottom 5%",
        //toggleActions:"restart reset play"
      }
    }).delay(0.2);

    // gsap.from(".lel5", {
    // duration:3, transformOrigin: "50% 50%", rotation: 30, scale: .5, opacity:0,
    //   scrollTrigger: {
    //     trigger:".lel5",
    //     start:"top 75%",
    //     end:"bottom 5%",
    //     //toggleActions:"restart reset play"
    //   }
    // }).delay(1.5);

    gsap.from(".lel1", {
    duration:1, transformOrigin: "50% 50%", scale: 1.8, opacity:0, rotation: 20,
      scrollTrigger: {
        trigger:".lel1",
        start:"top 75%",
        end:"bottom 5%",
        //toggleActions:"restart reset play"
      }
    }).delay(1.4);

    gsap.from(".lelm1", {
    duration:1, transformOrigin: "80% 20%", scale: .4, opacity:0, rotation: 30,
      scrollTrigger: {
        trigger:".lel1",
        start:"top 75%",
        end:"bottom 5%",
        //toggleActions:"restart reset play"
      }
    }).delay(0);

    gsap.from(".lelm2", {
    duration:.8, transformOrigin: "0% 100%", scale: .4, opacity:0, rotation: 30,
      scrollTrigger: {
        trigger:".lel1",
        start:"top 75%",
        end:"bottom 5%",
        //toggleActions:"restart reset play"
      }
    }).delay(.4);

    gsap.from(".lelm3", {
    duration:.8, transformOrigin: "100% 0%", scale: .4, opacity:0, rotation: 40,
      scrollTrigger: {
        trigger:".lel1",
        start:"top 75%",
        end:"bottom 5%",
        //toggleActions:"restart reset play"
      }
    }).delay(.6);

    gsap.from(".lelm4", {
    duration:.8, transformOrigin: "100% 0%", scale: .4, opacity:0, rotation: 30,
      scrollTrigger: {
        trigger:".lel1",
        start:"top 75%",
        end:"bottom 5%",
        //toggleActions:"restart reset play"
      }
    }).delay(.7);

    // gsap.from(".lel4", {
    // duration:3, transformOrigin: "50% 50%", rotation: -30, scale: .5, opacity:0,
    //   scrollTrigger: {
    //     trigger:".lel4",
    //     start:"top 75%",
    //     end:"bottom 5%",
    //     //toggleActions:"restart reset play"
    //   }
    // }).delay(2.5);

    // //line down
    // gsap.from(".lel6", {
    // duration:1, transformOrigin: "50% 50%", opacity:0,
    //   scrollTrigger: {
    //     trigger:".lel6",
    //     start:"top 75%",
    //     end:"bottom 5%",
    //     //toggleActions:"restart reset play"
    //   }
    // }).delay(4.5);

    // //line right
    // gsap.from(".lel7", {
    // duration:1, transformOrigin: "50% 50%", opacity:0,
    //   scrollTrigger: {
    //     trigger:".lel6",
    //     start:"top 75%",
    //     end:"bottom 5%",
    //     //toggleActions:"restart reset play"
    //   }
    // }).delay(4.5);

    // //line left
    // gsap.from(".lel8", {
    // duration:1, transformOrigin: "50% 50%", opacity:0,
    //   scrollTrigger: {
    //     trigger:".lel6",
    //     start:"top 75%",
    //     end:"bottom 5%",
    //     //toggleActions:"restart reset play"
    //   }
    // }).delay(4.5);
    
    //circle
    // gsap.from(".lel2", {
    //   duration:2, transformOrigin: "50% 50%", scale: 4, opacity:0, rotation: 90,
    //   scrollTrigger: {
    //     trigger:".lel2",
    //     start:"top 75%",
    //     end:"bottom 5%",
    //     //toggleActions:"restart reset play"
    //   }
    // });

    // //octagon
    // gsap.from(".lel3", {
    //   duration:3, transformOrigin: "50% 50%", scale: .7, opacity:0,
    //   scrollTrigger: {
    //     trigger:".lel3",
    //     start:"top 75%",
    //     end:"bottom 5%",
    //     //toggleActions:"restart reset play"
    //   }
    // });

    gsap.to('.logo-brand', {opacity:0, duration: 0});
    gsap.to('.logo-brand', {scrollTrigger:{ trigger:".pages", start: "top center"}, opacity:1, duration: 1});
    gsap.to('.logo-brand', {scrollTrigger:{ trigger:".welcome", start: "bottom 70%"}, opacity:0, duration: 1});
    
    gsap.to('.chat-button', {opacity:0, duration: 0});
    gsap.to('.chat-button', {scrollTrigger:{ trigger:".pages", start: "top center"}, opacity:1, duration: 1})

  }
}
</script>
<style scoped>
.home-logo{
  width:350px;
}
@media only screen and (min-width: 600px) {
    .home-logo{
      width:500px;
    }
}

.section-body{
  background-image: url('https://prysma-constructions.com/img/info-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #fcfcfc;
}
.home {
  /* background-image: url(../assets/cover.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 95vh;
  display: flex;
  flex-flow: column;
  /* justify-content: center; */
  justify-content: space-between;
  text-align: center;
  color: #ffffff;
  background:linear-gradient(0deg, #252f38 0%, #405365 100%);

}

.about {
  min-height: 95vh;
 
  /* background-color: #fcfcfc; */
  /* padding-top: 8em; */
}
.services {
  min-height: 95vh;
}
.portfolio {
  min-height: 95vh;
}
.quote {
  min-height: 95vh;
}
.contact {
  min-height: 95vh;
}
.logo-title{fill:#FFFFFF;}
.logo-subtitle{fill:#FFFFFF;}
.logo-el{fill:#FFFFFF;}

.social{
  /* margin-top:auto; */
  margin-bottom: 40px;
  cursor: pointer;
}


.title-hr{
width:100px;
height: 2px;
text-align:left;
background: #000000;
margin-top:0;
margin-left:-30px;
transition: all 1s;
}
.title-container{
  position: relative;
}
.section-title{
  color: #252f38;
}
.section-title:after{
    content: '';
    width: 100px;
    border-bottom: solid 3px #405365;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;
}

.aftercontact-title{
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 2em;
  text-transform: uppercase;
  margin-bottom:20px;
}

.ruler {
    background-image:
  linear-gradient(0deg, 
      rgba(73, 73, 73, 0.5) 0, 
      rgba(73, 73, 73, 0.5) 2%, 
      transparent 2%
    ), 
    linear-gradient(90deg, 
      #fcfcfc 50%, 
      transparent 50%
    ), 
    linear-gradient(0deg, 
      transparent 50%, 
      rgba(73, 73, 73, 0.5) 50%, 
      rgba(73, 73, 73, 0.5) 52%, 
      transparent 52%
    ), 
    linear-gradient(90deg, 
      #fcfcfc 70%, 
      transparent 70%
    ), 
    linear-gradient(0deg, 
      transparent 10%,
      rgba(73, 73, 73, 0.4) 10%, 
      rgba(73, 73, 73, 0.4) 12%, 
      transparent 12%, 
      
      transparent 20%,
      rgba(73, 73, 73, 0.4) 20%, 
      rgba(73, 73, 73, 0.4) 22%, 
      transparent 22%, 
      
      transparent 30%, 
      rgba(73, 73, 73, 0.4) 30%,
      rgba(73, 73, 73, 0.4) 32%, 
      transparent 32%, 
      
      transparent 40%, 
      rgba(73, 73, 73, 0.4) 40%, 
      rgba(73, 73, 73, 0.4) 42%, 
      transparent 42%, 
      
      transparent 60%, 
      rgba(73, 73, 73, 0.4) 60%, 
      rgba(73, 73, 73, 0.4) 62%, 
      transparent 62%, 
      
      transparent 70%, 
      rgba(73, 73, 73, 0.4) 70%, 
      rgba(73, 73, 73, 0.4) 72%, 
      transparent 72%, 
      
      transparent 80%, 
      rgba(73, 73, 73, 0.4) 80%, 
      rgba(73, 73, 73, 0.4) 82%, 
      transparent 82%, 
      
      transparent 90%, 
      rgba(73, 73, 73, 0.4) 90%, 
      rgba(73, 73, 73, 0.4) 92%, 
      transparent 92%
    );
  background-size:20px 50px;
  min-height:20px;
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-position-x: 100%;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

@media only screen and (max-width: 600px) {
  .portfolio-grid {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 700px) {
  .portfolio-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 992px) {
  .portfolio-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.span-col2 {
  grid-column: span 2;
}


#roller{
  position:absolute;
  right: 20px;
  z-index:99;
  color: #8a8a8a73;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}



.bounce {
	-moz-animation: bounce 3s infinite;
	-webkit-animation: bounce 3s infinite;
	animation: bounce 3s infinite;
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
</style>
