<template> 
    <div class="grid-box-picture">
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>     
       <div class="grid-box-picturebox" :class="{'active': isLoaded}"          
           v-bind:style="{ 'background-image':'url('+image+')'}"> 
       </div>
    </div>     
</template>

<script>
export default {
  props: {
    externalUrl: String
  },
  data(){
    return{      
      image: '',
      imageUrl: '',
      isLoaded: false,
      isLoading: true
      }
  },
  mounted() {
    this.setImage();
  },
  methods: {        
    setImage() {
           
      this.imageUrl = this.externalUrl;
      this.image = '';
      var that;
      var highResImage = new Image();
      that = this;
      highResImage.onload = function(){
        that.image = that.imageUrl; 
        that.isLoaded = true;
        that.isLoading = false;
        console.log("loaded");
      }
      highResImage.src = this.imageUrl;
    }
  }

}
</script>
<style scoped>
.grid-box-picturebox{
  width: 100%;
  min-height: 600px;
  /* padding: 20px; */  
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  opacity: 0;
}
  .active{
    opacity: 1;
  }

</style>