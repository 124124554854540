import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang:"en",
    activeScroll: 0,
    token: null,
    modals:{
      portfolio: false
    },
    portData: {}
    // showfolio: {
    //   show: false,
    //   data: {}
    // }
  },
  mutations: {
    SET_MODAL(state, payload) {
      //state[Object.keys(payload)[0]] = Object.values(payload)[0];
      state.portData = payload.pdata;
      state.modals[payload.modal] = payload.state;
    },
    CHANGE_LANG(state) {
      if(state.lang == "en"){
        state.lang = "nl";
      }else{
        state.lang = "en";
      }
    },
    SET_LANG(state, prop) {
      state.lang = prop;
    },
    SET_ACTIVE(state, prop) {
      state.activeScroll = prop;
    },
    SET_TOKEN(state, prop) {
      state.token = prop;
    },
  },
  actions: {
  },
  modules: {
  }
});
