import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";
import Buefy from "buefy";
//import "buefy/dist/buefy.css";
import marked from "marked";
import VueScrollTo from "vue-scrollto";

Vue.config.productionTip = false;

//axios.defaults.baseURL = "https://newadmin.prysma-constructions.com/api/";

Vue.prototype.$mySite = {
  title: "Prysma Constructions",
  url: "https://prysma-constructions.com",
  shareImage: "https://prysma-constructions.com/img/share.jpg",
  resurces: "https://newadmin.prysma-constructions.com",
  token: "e51e9fccaa0b92f15b4171b95e9ec341c18ac5b7c1f6eb9ba79d687ed9510565888889fa06f902268fbb19a337165b7c6be71546fceaae8bbe4c27c6b419cfabcc86e23380fa8d911889791ff81f0a93ce3d6bf50d076cda05bc68c5baad4cdb5951330e9afdc3fed0e127be52e1d24040e6ffa8ed710dddac6d89655e8a3693",
};

Vue.prototype.$locales = {
  en:{
    home: "Home",
    about: "About Us",
    why: "Why Us?",
    services: "Services",
    portfolio: "Portfolio",
    quote: "iQuote",
    cont: "Contact Us",
    language: "Dutch",
    more: "View Details",
    form:{
      email: "Please enter a valid email address.",
      text: "Please fill out this field.",
      human: "You did not pass the human test.",
      error: "Ooops! Something went wrong. Please try again."
    }
  },
  nl:{
    home: "Huis",
    about: "About Us",
    why: "Waarom wij?",
    services: "Diensten",
    portfolio: "Portefeuille",
    quote: "iKoers",
    cont: "Contact",
    language: "English",
    more: "Details bekijken",
    form:{
      email: "Vul een geldig e-mailadres in.",
      text: "Vul alstublieft dit veld in.",
      human: "U bent niet geslaagd voor de menselijke test.",
      error: "Oeps! Er is iets fout gegaan. Probeer het alstublieft opnieuw."
    }
  }
};

// Vue.use({
//   install () {
//     Vue.marked = marked;
//     Vue.prototype.$marked = marked;
//   }
// });
Vue.use(Buefy, {
  defaultIconPack: "fas"
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.use(VueAxios, axios);
Vue.use(VueScrollTo,{
  container: "body",
  duration: 500,
  easing: "ease-out",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
