<template>
    <!-- <b-carousel v-model="isActive" :pause-inf="false" :autoplay="false" @change="info($event)"> -->
        <!-- <b-carousel-item v-for="(item, i) in indata" :key="i">
             
               <GalleryImage :externalUrl="$mySite.resurces + item.attributes.url" />
        </b-carousel-item> -->
<!-- </b-carousel> -->

<div class="slide-gall">
      <Flicking :options="{ align: 'center', circular: true}" ref="flicking" v-if="indata.length > 0">
         <div v-for="(item, i) in indata" class="flicking-panel" :key="'image-'+i">
          <GalleryImage :externalUrl="$mySite.resurces + item.attributes.url" />
            <!-- <img :alt="advertTitle + ' photo '+ (i+1)" class="image-item" @click="openGall()" :src="showImage(item.image)" loading="lazy" />      -->
        </div>
    </Flicking>
    <div class="control-buttons">
        <button class="control-back" @click="goBack()"><b-icon icon="angle-left"/></button>
        <button class="control-next" @click="goNext()"><b-icon icon="angle-right"/></button>
    </div>  
  </div>
    
</template>

<script>
import GalleryImage from '@/components/GalleryImage.vue' 
import { Flicking } from "@egjs/vue-flicking";
export default {
  props: {
    indata: Array
  },
  components: {
    GalleryImage,
    Flicking: Flicking,
  },
  data(){
    return{
      isActive: 0,
    }
  },
  methods:{
    goBack(){
            const currentPanelIndex = this.$refs.flicking.index;
            if(currentPanelIndex == 0){
                this.$refs.flicking.moveTo(this.countItems-1);
            }else{
                this.$refs.flicking.moveTo(currentPanelIndex-1);
            }
           // $refs.flicking.prev();
        },
        goNext(){
            const currentPanelIndex = this.$refs.flicking.index;
            if(currentPanelIndex == this.countItems-1){
                this.$refs.flicking.moveTo(0);
            }else{
                this.$refs.flicking.moveTo(currentPanelIndex+1);
            }
            //$refs.flicking.next();
        },
    handeleKey(e){
      console.log("COUNT: "+this.count);
      if (e.key=="ArrowLeft"){
        console.log("ArrowLeft");
        if(this.isActive>0){this.isActive=this.isActive-1}else{
          this.isActive = this.count;
        }
        //flicking
        this.goBack();
      }else if(e.key=="ArrowRight"){
        console.log("ArrowRight");
        if(this.isActive < this.count){this.isActive=this.isActive+1}else{
          this.isActive = 0;
        }
                //flicking
                this.goNext();
      }else{
        console.log("not tracked keys")
      }
    },
    info(e){
      console.log(e);
      this.isActive = e;
    }
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handeleKey(event));
  },
  mounted(){
    document.addEventListener("keyup", this.handeleKey);
  },
  computed:{
    count(){
      return this.indata.length-1;
    } 
  }
  
}
</script>
<style scoped>
.gallery-image{
  width: 100%;
  min-height: 600px;
  /* padding: 20px; */  
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
}
.flicking-panel{
  height: 600px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
}

.slide-gall{
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.control-back, .control-next {
    all: unset;
    display: flex;
    /* background-color: rgba(0, 0, 0, 0.5); */
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;
    padding: 15px 15px;
    font-size: 2rem;
    line-height: 1;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: center;
    align-content: center;
    z-index: 1;
    transform: translateY(-50%);
}
.control-back{
    position: absolute;
    top: 50%;
    left: -10px;
    border-radius: 0 50% 50% 0;
    box-shadow: inset -3px 0px 6px 0px rgb(193 193 193 / 40%);
    border: 1px solid #ffffff;
}
.control-next{
    position: absolute;
    top: 50%;
    right: -10px;
    border-radius: 50% 0 0 50%;
    box-shadow: inset 3px 0px 6px 0px rgb(193 193 193 / 40%);
    border: 1px solid #ffffff;
}
.control-back:hover, .control-next:hover {
    background-color: #f1f1f1; /* darker on hover */
}

</style>