<template>
  <div class="tcontainer"
    @click="showModal(indata)"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="{ active: hover }"
  >
    <div class="portfolio-item doflex">
 
        <div class="portfolio-title">{{indata.attributes.Title}} <hr class="title-hr"> </div>
         <div class="button-container"><div class="portfolio-button">{{$locales[lang].more}}</div></div>
    
    </div>
    <div class="portfolio-image" v-bind:style="{backgroundImage: 'url(' + $mySite.resurces + indata.attributes.Thumbnail.data.attributes.url + ')'}">
      <!-- <div class="portfolio-title-container">
        <div class="portfolio-title">{{indata.title}} <hr class="title-hr"> </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PortfolioItem",
  props: {
    indata: Object
  },
  data() {
    return {
      hover: false  
    };
  },
  methods:{
    showModal(data){
      this.$store.commit("SET_MODAL", {modal:"portfolio", state:true, pdata:data});
      console.log(data)
    }
  },
  computed: {
    ...mapState(["lang"]),
  }
};
</script>

<style scoped>
  .tcontainer{
    position: relative;
    cursor: pointer;
 /* border-left: solid #000000 1px;
    border-bottom: solid #000000 1px;
    padding-left:10px;
    padding-bottom:10px; */

  }

  .title-hr{
    width:100px;
    height: 2px;
    text-align:left;
    background: #000000;
    margin-top:12px;
    margin-bottom: 0px;
    margin-left:-20px;
    transition: all 1s;
  }

  .portfolio-title-container{
    padding-left:20px;
  }

  .portfolio-title{
    color: #000000;
    text-transform: uppercase;
    font-size: 2rem;
    padding: 20px 0 0px 20px;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
  }

  .portfolio-text{
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #262c3a;
  }

  .portfolio-image{
    width: 100%;
    min-height: 400px;
    /* padding: 20px; */  
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    transition: all 1s;
    /* filter: blur(5px);
    opacity: 0.2; */
      /* display: flex;
      flex-direction: column;
    justify-content: flex-end; */
  }

  .portfolio-item{
    /* padding: 0px 20px 20px 20px; */
    position: absolute;
    z-index: 22;
    opacity: 0;
    transition: all 1s;
    width: 100%;
     min-height: 400px;
  }

  .active .portfolio-item{
    opacity: 1;
  }

  .active .portfolio-image{
    filter: blur(3px);
    opacity: 0.5;
  }

  .active .title-hr{
    width:100%;
    margin-left:-20px;
  }

  /* .active .portfolio-title-container{
    opacity: 0;
  } */
.doflex{
 display: flex;
 flex-flow: column;
 align-content: space-between;
}

.button-container{
margin-top:auto;
}
  .portfolio-button{

    background-color: #252f38;
    padding: 20px;
    /* margin-left:-20px; */
    color: #fff;
    text-transform: uppercase;
    float: right;


  }


    @media only screen and (max-width: 600px) {
  
  .portfolio-item{
    opacity: 1;
  }

  .portfolio-image{
    filter: unset;
    opacity: 1;
  }

  .title-hr{
    width:100%;
    margin-left:-20px;
  }

  .portfolio-title {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.5);  
  }

  }

</style>