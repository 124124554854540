<template>
    <b-navbar class="navbar" wrapper-class="container" type="is-dark" :fixed-top="true">
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }" class="logo-brand">
                <img 
                    src="/img/logo_sm_white.svg"
                    width="177.7px"
                >
            </b-navbar-item>
        </template>
        <template slot="end">
          <b-navbar-item :active="activeScroll === 0" v-scroll-to="{ el: '.home', duration: 500, offset: -60 }">
              {{$locales[lang].home}}
            </b-navbar-item>
            <!-- <b-navbar-item :active="activeScroll === 2" v-scroll-to="{ el: '.about', duration: 500, offset: -60 }">
                {{$locales[lang].about}}
            </b-navbar-item> -->
            <b-navbar-item :active="activeScroll === 1" v-scroll-to="{ el: '.services', duration: 500, offset: -60 }">
                {{$locales[lang].services}}
            </b-navbar-item>
            <b-navbar-item :active="activeScroll === 2" v-scroll-to="{ el: '.why', duration: 500, offset: -60 }">
                {{$locales[lang].why}}
            </b-navbar-item>
            <b-navbar-item :active="activeScroll === 3" v-scroll-to="{ el: '.portfolio', duration: 500, offset: -60 }">
                {{$locales[lang].portfolio}}
            </b-navbar-item>
            <!--to-activate-quote: <b-navbar-item :active="activeScroll === 4" v-scroll-to="{ el: '.quote', duration: 500, offset: -60 }">
                {{$locales[lang].quote}}
            </b-navbar-item> -->
            <b-navbar-item :active="activeScroll === 5" v-scroll-to="{ el: '.contact', duration: 500, offset: -60 }">
                {{$locales[lang].cont}}
            </b-navbar-item>
            <b-navbar-item @click="switchLang">
                {{$locales[lang].language}}
            </b-navbar-item>
        </template>
    </b-navbar>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: 'Navbar',
    data() {
        return {         
        };
    },
    methods: {
        switchLang (){
            this.$store.commit("CHANGE_LANG");
        },    
    },    
    computed: {
        ...mapState(["lang","activeScroll"]),
    },
}
</script>
<style>
.navbar{
    text-transform: uppercase!important;
    font-family: 'Oswald', sans-serif;
    font-weight: 300!important;
}

</style>