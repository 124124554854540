<template>
  <section>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="form-title">{{trans[lang].title}}</div>

    <div class="form-container">
      <b-field 
        :label="trans[lang].form.name"
        :type="form.name.status"
        :message="form.name.responce"
        >
        <b-input type="text" v-model="form.name.data" required></b-input>
      </b-field>

      <b-field
        :label="trans[lang].form.email"
        :type="form.email.status"
        :message="form.email.responce"
        >
        <b-input type="email"  v-model="form.email.data" required></b-input>
      </b-field>

      <b-field         
        :label="trans[lang].form.phone"
        :type="form.phone.status"
        :message="form.phone.responce">
        <b-input type="text" v-model="form.phone.data"></b-input>
      </b-field>

      <b-field 
        :label="trans[lang].form.service.label"
        :type="form.service.status"
        :message="form.service.responce"
      >
        <b-select :placeholder="trans[lang].form.service.placeholder" v-model="form.service.data" expanded>
          <template v-for="(item, index) in trans[lang].form.service.options">
            <option
              v-bind:key="'service' + index"
              :value="item"
            >{{ item }}</option
          >
          </template>
        </b-select>
      </b-field>

      <b-field grouped>
        <b-field :label="trans[lang].form.period.label_from" expanded>
          <b-timepicker
            :placeholder="trans[lang].form.period.placeholder"
            icon="clock"
            editable
            v-model="form.period.data[0]"
            :default-minutes="0"
          >
          </b-timepicker>
        </b-field>

        <b-field :label="trans[lang].form.period.label_to" expanded>
          <b-timepicker
            :placeholder="trans[lang].form.period.placeholder"
            icon="clock"
            editable
            v-model="form.period.data[1]"
            :default-minutes="0"
          >
          </b-timepicker>
        </b-field>
      </b-field>

      <b-message v-if="form.result" :type="form.status">{{form.responce}}</b-message>

      <b-button v-if="form.visible" @click="sendCallback()" type="is-dark">{{trans[lang].button}}</b-button>

    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
import formValidator from "@/mixins/formValidator";
export default {    
    name: 'CallbackForm',
    data() {
        return {        
          trans:{
            en: {
              title: "Request Callback",
                form: {
                  name:"Name",
                  email:"Email",
                  phone:"Phone",
                  service:{
                    label:"Service",
                    placeholder:"Select a service",
                    options: ["Detailed Quotation", "Design", "Refurbishment and Renovation", "Ask an Engineer", "Schedule a Site Visit"]
                  },
                  period:{
                    label_from:"Period From",
                    label_to:"To",
                    placeholder:"Type or select a time...",
                  }               
                },
                button:"Send Request"
            },
            nl: {
                title: "Terugbelverzoek",
                form: {
                  name:"Naam",
                  email:"Email",
                  phone:"Telefoon",
                  service:{
                    label:"Onderhoud",
                    placeholder:"Selecteer een dienst",
                    options: ["Gedetailleerde offerte", "Ontwerp", "Renovatie ", "Vraag een ingenieur", "Plan een locatiebezoek"]
                  },
                  period:{
                    label_from:"Periode van",
                    label_to:"Tot",
                    placeholder:"Typ of selecteer een tijd...",
                  }                 
                },
                button:"Verzend Verzoek"
            },
          },
          isLoading: false,
          form: {
            name: {
              data: "",
              status: "",
              responce: ""
            },
            email: {
              data: "",
              status: "",
              responce: ""
            },
            phone: {
              data: "",
              status: "",
              responce: ""
            },
            service: {
              data: "",
              status: "",
              responce: ""
            },
            period: {
              data: [],
              status: "",
              responce: ""
            },
            human: {
              lable:"",
              summ: null,
              data: null,
              status: "",
              responce: ""
            },
            visible: true,
            responce: "",
            result: false,
            status: ""
          }
        };
    },
    mixins: [formValidator],
    methods: {
      sendCallback() {
         if (this.emailCheck("email") & this.formCheck("phone") & this.formCheck("name") & this.formCheck("service")) {
          //this.form.visible = false;
          this.isLoading = true;
          //begin post --------------------------------------------
          let period_from = ["-","-"];
          let period_to = ["-","-"];
          if(this.form.period.data[0]){
            period_from[0] = this.form.period.data[0].getHours();
            period_from[1] = this.form.period.data[0].getMinutes();
          }
          if(this.form.period.data[1]){
            period_to[0] = this.form.period.data[0].getHours();
            period_to[1] = this.form.period.data[0].getMinutes();
          }
          this.axios
            .post("https://prysma-constructions.com/api/sendCallback.php", {
              action: "send",
              name: this.form.name.data,
              email: this.form.email.data,
              phone: this.form.phone.data,
              service: this.form.service.data,
              period_from: period_from[0]+':'+period_from[1],
              period_to: period_to[0]+':'+period_to[1],
              token: this.$store.state.token
            })
            .then((response) => {
              this.isLoading = false;
              this.form.result = true;
              this.form.responce = response.data.message;
              console.log(response.data);
              if (response.data.success) {
                this.form.status = "is-success";
                this.form.visible = false;
              } else {
                this.form.status = "is-danger";
              }
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
              //this.hasError();
            });
          //end post-------------------------------------------------
        } else {
          this.form.status = "is-danger";
          this.form.result = true;
          this.form.responce = "Ooops! Something went wrong. Please try again.";
        }
      },     
    },    
    computed: {
        ...mapState(["lang"]),
    },
}
</script>
<style scoped>
  .form-title{
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 2em;
    text-transform: uppercase;
    position: relative;
  }
  .form-container{
    background: #ffffff;
    margin-top: 50px;
    padding: 40px 40px 40px 40px;
    border-left:2px #405365 solid;
  }
</style>