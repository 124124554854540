<template>
  <div id="app">
    <Navbar/>
    <router-view/>
    <PortfolioModal/>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import PortfolioModal from '@/components/PortfolioModal.vue'
export default {
  components: {
    Navbar,
    PortfolioModal
  },
  mounted(){
    console.log("!!!!");
  }
}
</script>
<style>
@import 'static/fontawesome/css/all.min.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Stint+Ultra+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap');
@import url("@egjs/vue-flicking/dist/flicking.css");
.router-container{
    min-height: calc(100vh - 250px);
}
.footer {
    background-color: #fafafa;
    padding: 3rem 1rem 3rem !important;
}
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #262c3a;
}
h1{
  font-family: 'Oswald', sans-serif;
  font-weight: 300!important;
  font-size: 3em!important;
  text-transform: uppercase;
  }

.imgb {
  display: block;
  background: transparent;
  padding: 8px;
  border: 1px solid #ccc;
  /* border-radius: 8px; */
  /* box-shadow: 10px 10px 10px #999; */
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.navbar-item img {
     max-height: none!important;
}

</style>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #405365; //green #215D63; //#00bb8f; //00D1B2
$primary-invert: findColorInvert($primary);
//theme mod
$mydark: #181d29; //#00bb8f; //00D1B2
$dark: #405365;
$filter: #F4F4F4; //#00bb8f; //00D1B2
$sidebar-box-shadow: 0px;
$sidebar-width: 330px;
$body-background-color: #f5f5f5;
$content-heading-color: #000000;


// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "mydark": ($mydark, $white),
    "filter": ($filter, $white),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

$sizeUnit: rem;
$marginKey: 'm';
$paddingKey: 'p';
$separator: '-';
$sizes: (
    ('none', 0),
    ('xxs', 0.125),
    ('xs', 0.25),
    ('sm', 0.5),
    ('md', 1),
    ('lg', 2),
    ('xl', 4),
    ('xxl', 8),
);
$positions: (
    ('t', 'top'),
    ('r', 'right'),
    ('b', 'bottom'),
    ('l', 'left')
);

$doublepositions: (
    ('y', 'top', 'bottom'),
    ('x', 'left', 'right'),
);

@function sizeValue($key, $value) {
    @return if($key == 'none', 0, $value + $sizeUnit);
}

@each $size in $sizes {
    $sizeKey: nth($size, 1);
    $sizeValue: nth($size, 2);
    .#{$marginKey}#{$separator}#{$sizeKey} {
        margin: sizeValue($sizeKey, $sizeValue);
    }
    .#{$paddingKey}#{$separator}#{$sizeKey} {
        padding: sizeValue($sizeKey, $sizeValue);
    }
    @each $position in $positions {
        $posKey: nth($position, 1);
        $posValue: nth($position, 2);
        .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            margin-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }
        .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            padding-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }
    }
    @each $position in $doublepositions {
        $posKey: nth($position, 1);
        $posValue1: nth($position, 2);
        $posValue2: nth($position, 3);
        .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            margin-#{$posValue1}: sizeValue($sizeKey, $sizeValue);
            margin-#{$posValue2}: sizeValue($sizeKey, $sizeValue);
        }
        .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            padding-#{$posValue1}: sizeValue($sizeKey, $sizeValue);
            padding-#{$posValue2}: sizeValue($sizeKey, $sizeValue);
        }
    }
}
</style>
