<template>
  <b-modal :active.sync="modals.portfolio">
    <!-- <a @click="closeModal()" class="delete is-large close-bt"></a> -->
    <div class="pcontainer">
      <div>
      <div class="columns">
        <div class="column has-text-right gallery-container">
          <PortfolioGallery class="gallery" :indata="portData.attributes.Gallery.data" />
        </div>
        <div class="column has-text-left">
          <div class="content text-bloc has-text-light">
            <div class="port-title">{{portData.attributes.Title}} <hr class="title-hr"> </div>
            <div v-if="portData.attributes.Description" class="port-text" v-html="reMarked(portData.attributes.Description)"></div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { marked } from 'marked';
import PortfolioGallery from '@/components/PortfolioGallery.vue';
export default {
  name: "PortfolioModal",
  components: {
    PortfolioGallery
  },
  data() {
    return {
      hover: false  
    };
  },
  methods:{
    closeModal(){
      this.$store.commit("SET_MODAL", {modal:"portfolio", state:false});
    },
    reMarked(text){
      return marked.parse(text);
    },
  },
  computed: {
    ...mapState(["modals","portData"]),
  }
};
</script>

<style scoped>
.close-bt{
  position: fixed;
  top:20px;
  right:30px;
  z-index:99;
}

.pcontainer{
  overflow: hidden;
  min-height: 600px;
}

.gallery-container{
  padding: 0 30px 0 30px;
}

.title-hr{
  width:100px;
  height: 2px;
  text-align:left;
  background: #ffffff;
  margin-top:12px;
  transition: all 1s;
}

.port-title-container{
  padding-left:20px;
}

.port-title{
  text-transform: uppercase;
  font-size: 2rem;
  padding: 20px 0 20px 0px;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
}

.port-text{
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-bloc{
  padding: 20px;
  display: block;
}

</style>