<template>
  <div class="tcontainer" 
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="{ active: hover }"
  >
    <div class="info-bg" v-bind:style="{backgroundImage: 'url(' + $mySite.resurces + indata.attributes.Image.data.attributes.url + ')'}">
      <div class="info-title-container doflex">
        <div class="info-title">{{indata.attributes.Title}} <hr class="title-hr"> </div>
        <div class="button-container"><div class="info-button">{{$locales[lang].more}}</div></div>
      </div>
    </div>      
    <div class="info">
      <div class="content"> 
        <div class="info-title">{{indata.attributes.Title}} <hr class="title-hr"> </div>
        <div class="info-text" v-html="reMarked(indata.attributes.Description)"></div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { marked } from 'marked';
export default {
  name: "InfoItem",
  props: {
    indata: Object
  },
  data() {
    return {
      hover: false  
    };
  },
  computed: {
    ...mapState(["lang"]),
  },
  methods:{
    reMarked(text){
      return marked.parse(text);
    },
  }
};
</script>

<style scoped>
  .tcontainer{
    position: relative;
    cursor: pointer;
      /* border-left: solid #000000 1px;
    border-bottom: solid #000000 1px;
    padding-left:10px;
    padding-bottom:10px; */

  }

  .title-hr{
  width:100px;
  height: 2px;
  text-align:left;
  background: #000000;
  margin-top:12px;
  margin-left:-20px;
  transition: all 1s;
  }

  .info-title-container{
    padding-left:20px;
    height: 580px;
  }


  .info-title{
    color: #000000;
    text-transform: uppercase;
    font-size: 2rem;
    padding: 20px 0 20px 0px;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    /* border-bottom: solid #3a3a3a 1px; */
    /* margin-left: -100px; */
  }

  .info-text{
      font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #262c3a;
  }

  .info-bg{
    width: 100%;
    height: 600px;
    /* padding: 20px; */  
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    transition: all 1s;
    /* filter: blur(5px);
    opacity: 0.2; */
      /* display: flex;
      flex-direction: column;
    justify-content: flex-end; */
  }

  .info{
    padding: 0px 20px 20px 20px;
    position: absolute;
    z-index: 22;
    opacity: 0;
    transition: all 1s;
    top: 0;
  }

  .active .info{
    opacity: 1;
  }

  .active .info-bg{
    filter: blur(5px);
    opacity: 0.2;
  }

  .active .title-hr{
  width:100%;
  /* margin-left:-30px; */
  }

  .doflex{
    display: flex;
    flex-flow: column;
    align-content: space-between;
  }

  .button-container{
    margin-top:auto;
  }

  .info-button{
    background-color: #252f38;
    padding: 20px;
    /* margin-left:-30px; */
    color: #fff;
    display: inline-block;
    text-transform: uppercase;
    opacity: 0;
  }

  @media only screen and (max-width: 600px) {
    /* .info-button{
      opacity: 1;
    } */
    .info{
      opacity: 1;
      position:unset;
      margin-top: 30px;
    }
    .info .info-title{
      display: none;
    }

    .info-bg{
      /* filter: blur(5px);
      opacity: 0.2; */
      height: 300px;
    }

    .title-hr{
      width:100%;
      margin-top:0px;
      margin-left:-20px;
    }
  }

</style>